<template>
  <div>
    <banner-detail :title="title" :banner-url="backgroundPhotoUrl"/>
    <div class="h1">{{title}}
  </div>
    <div class="layout">
      <k15 v-if="groupType==='parentChild'" :scroll-top="scrollTop" @go-anchor="goAnchor" v-model="currentTabs"/>
      <div class="details">
        <div class="cover">
          <img class="img" :src="photoUrl"/>
        </div>
        <div class="info">{{introduction}}</div>
      </div>
      <detail-group v-for="(item,index) of modules"
                    :id="`K${index+1}`"
                    :group="item"
                    @reload="getDetail"
                    :key="item.moduleId"
                    v-waypoint="{active: true, callback: onWaypoint}"/>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import VueWaypoint from 'vue-waypoint';
  import BannerDetail from './components/BannerDetail';
  import DetailGroup from './components/DetailGroup';
  import K15 from './components/K15';
  import {getNearEle, scrollToY} from '../../utils';
  import {getFeaturedDetail} from '../../api/featured';

  Vue.use(VueWaypoint);
  export default {
    name: 'FeaturedDetail',
    data() {
      return {
        groupId: '',
        title: '',
        backgroundPhotoUrl: '',
        photoUrl: '',
        introduction: '',
        groupType: '',
        modules: [],
        scrollTop: 0,
        currentTabs: 'K1'
      };
    },
    components: {K15, BannerDetail, DetailGroup},
    created() {
      this.groupId = this.$route.query.id;
      this.getDetail();

      this.$nextTick(() => {
        document.querySelector('#app').addEventListener('scroll', this.onScroll);
      });
    },
    destroyed() {
      document.querySelector('#app').removeEventListener('scroll', this.onScroll);
    },
    methods: {
      onScroll() {
        this.scrollTop = document.querySelector('#app').scrollTop;
      },
      // 锚点
      goAnchor(name) {
        const anchor = this.$el.querySelector('#' + name);
        const offsetTop = anchor.offsetTop;
        scrollToY(undefined, offsetTop - 120);
      },
      onWaypoint({el, going, direction}) {
        if (this.$waypointMap.GOING_IN === going && this.$waypointMap.DIRECTION_TOP === direction) {
          this.currentTabs = el.getAttribute('id');
        } else if (this.$waypointMap.GOING_OUT === going && this.$waypointMap.DIRECTION_BOTTOM === direction) {
          const $prev = getNearEle(document.querySelector('#' + el.getAttribute('id')), 1);
          if ($prev) {
            this.currentTabs = $prev.getAttribute('id');
          }
        }
      },
      // 获取详情
      getDetail() {
        getFeaturedDetail(this.groupId).then(res => {
          Object.assign(this.$data, res.value || {});
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .details{
    margin-top:30px;overflow:hidden;
    .cover{float:left;max-width: 460px;margin: 0 10px;}
  }
@media only screen and (max-width: 768px) {
     .info{text-align: center;margin-right:5px;}
   }

   .h1{display:inline-block;vertical-align:middle;text-shadow:3px 2px 0 #fff;color:#ff6f61;font-size:48px;font-weight:bold;}

   @media only screen and (min-width: 865px) {
     .h1{display: none;}
   }
</style>
