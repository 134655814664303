<template>
  <div class="banner" :style="{backgroundImage:`url(${bannerUrl})`}">
    <h1 class="h1">{{title}}</h1>
  </div>
</template>
<script>
  export default {
    name: 'BannerDetail',
    props: {
      title: String,
      bannerUrl: String
    }
  };
</script>
<style scoped lang="less">
  .banner{
    position:relative;height:576px;text-align:center;background-repeat:no-repeat;background-position:center;background-size: contain;
    &:after{display:inline-block;height:100%;vertical-align:middle;content:'';}
  }
  .h1{display:inline-block;vertical-align:middle;text-shadow:3px 2px 0 #fff;color:#ff6f61;font-size:48px;font-weight:bold;}

  @media only screen and (max-width: 865px) {
  .banner {
    display: none;
  }
}
</style>
