<template>
  <div id="K15Bar" class="k15" :class="{fixed: this.scrollTop >= this.offsetTop}">
    <ul>
      <li v-for="i in 15" :class="{active:`K${i}`===value}" :key="i">
        <a href="javascript:;" @click="select(i)">k{{i}}</a>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: 'K15',
    props: {scrollTop: Number, value: String},
    data() {
      return {
        offsetTop: 0
      };
    },
    created() {
      this.$nextTick(() => {
        this.offsetTop = document.getElementById('K15Bar').offsetTop - 60;
      });
    },
    methods: {
      select(value) {
        this.$emit('input', `K${value}`);
        this.$emit('go-anchor', `K${value}`);
      }
    }
  };
</script>
<style scoped lang="less">
  .k15{
    height:50px;
    &.fixed{
      ul{position:fixed;top:60px;z-index:999;}
    }
    ul{
      border-bottom:1px dashed #ff6f61;background-color:#fff;
      li{
        display:inline-block;position:relative;margin-right:10px;vertical-align:top;text-align:left;
        &.active{
          &:after{position:absolute;right:0;bottom:0;left:0;height:4px;background-color:#ff6f61;content:'';}
        }
      }
      a{display:inline-block;min-width:54px;vertical-align:top;line-height:50px;color:#321908;font-size:18px;}
    }
  }
</style>
